<template>
  <div class="home">
    <div class="home-top">
      <div class="home-top-logo">
        <img src="@/assets/images/logo.png" alt="">
        <span class="home-top-title">任务悬赏平台</span>
      </div>
    </div>
    <div class="home-content">
        <div class="home-content-core">
          <div class="home-content-logo">
            <img src="@/assets/images/center-logo.png" alt="">
          </div>
          <login></login>
        </div>
    </div>
    <div class="home-bottom"></div>
  </div>
</template>

<script>
import Login from './components/login.vue'

export default {
  data () {
    return {
      dialogVValue: true
    }
  },
  components: {
    Login
  }
}
</script>

<style lang="scss" scoped>
.home {
  width: 100%;
  height: 100%;
  min-width: 1218px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .home-top{
  width:100%;
  background-color: #FFFFFF;
  height: 60px;
  border-radius: 0px;
  .home-top-logo {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    line-height: 60px;
    padding-left: 20px;
    .home-top-title {
      padding-left: 10px;
      font-size: 20px;
      color: #000;
      font-weight: bold;
    }
  }
 }
 .home-content {
    min-height: 749px;
    background-color: #F3F3F3;
    flex:1;
    .home-content-core {
      width: 100%;
      display: flex;
      justify-content: center;
      .home-content-logo {
        width:718px;
        height: 650px;
      }
  }
 }
  .home-bottom {
    width: 100%;
    height: 160px;
    background: #313131;
    border-radius: 0px;
  }
}

</style>
