<template>
  <div class="login">
    <div class="login_title">
      <p class="tw-text-[20px]">欢迎登录后台</p>
    </div>
    <div class="login_form">
      <el-form
        ref="form"
        :model="form"
        :rules="rules">
        <el-form-item prop="mobile">
          <el-input
            v-model.trim="form.mobile"
            :maxlength="11"
            placeholder="请输入手机号">
          </el-input>
        </el-form-item>
        <el-form-item
          prop="password"
          @keydown.native.enter="loginToAdmin">
          <el-input
            type="password"
            v-model="form.password"
            placeholder="请输入密码"
            show-password>
          </el-input>
        </el-form-item>
      </el-form>
      <el-button
        type="primary"
        @click="loginToAdmin"
        :loading="btnLoading"
        style= "width:320px;">
        立即登录
      </el-button>
    </div>
    <div class="login-message">
      <span>当前平台处于试运营期间，如有问题，可联系本公司工作人员反馈</span>
    </div>
    <div class="content-logo-hidden">
      <img src="@/assets/images/content-logo-hidden.png" alt="">
    </div>
    <div class="home-content-right">
      <img src="@/assets/images/center-right.png" alt="">
    </div>
    <div class="right-hidden">
      <img src="@/assets/images/right-hidden.png" alt="">
    </div>
  </div>
</template>

<script>
import api from '@/api/index.js'
import cookie from '@/plugins/cookie'
import globalVar from '@/configs/globalVar'
import SHA256 from 'crypto-js/sha256'

export default {
  data () {
    return {
      btnLoading: false,
      form: {
        mobile: '', // 手机号
        password: '' // 密码
      },
      // 表单验证规则
      rules: {
        mobile: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          { pattern: /^1\d{10}$/, message: '手机号格式错误', trigger: 'blur' }
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          { pattern: /^(?=.*[a-zA-Z])(?=.*\d)[A-Za-z\d]{8,100}$/, message: '密码长度为8~16位，至少需要包含数字、字母', trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    loginToAdmin () {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.btnLoading = true
          api.adminLogin({
            mobile: this.form.mobile,
            password: SHA256(this.form.password).toString()
          }).then((res) => {
            this.btnLoading = false
            console.log(res)
            if (res.data.code === 0) {
              const { token, user } = res.data.data
              cookie.set(globalVar.TOKEN, token)
              this.$store.commit('setUserInfo', user)
              this.$router.push('/admin')
            } else {
              this.$message.error('账号或密码错误')
            }
          }).catch((error) => {
            this.btnLoading = false
            console.log(error)
            this.$message.error('请求出错')
          })
        } else {
          return false
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.login {
    position: relative;
    top: 50px;
    width:500px;
    height: 600px;
    padding: 60px 90px;
    border: 1px solid #fff;
    background-color:rgba(254, 254, 254, 1);
    border-radius: 4px;
    .login_title {
      width: 210px;
      height: 28px;
      font-size: 20px;
      font-weight: bold;
      line-height: 28px;
      color: #000000;
    }
    .login_form {
      margin-top:36px;
    }
    .login-message {
      position:absolute;
      bottom:36px;
      width: 320px;
      height: 60px;
      font-size: 12px;
      font-weight: 400;
      padding: 10px 10px 0px 10px;
      color: #909399;
      border-top: 1px solid #DCDFE6;
    }
    .content-logo-hidden{
      position: absolute;
      width: 80px;
      top: 0px;
      left: -1px;
    }
    .home-content-right {
      position: absolute;
      right:-96px;
      bottom: -100px;
    }
    .right-hidden {
      position: absolute;
      right: 0px;
      bottom:0px;
    }
  }
</style>
